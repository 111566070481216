import React from 'react';

import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import Footerinfo from './components/Footerinfo';
import Headerinfo from './components/Headerinfo';


function Home (){


	return( 
	<>
	
	
	<div class="page-wrapper">

	  			 <Headerinfo />
	
				   <section class="main-slider">
			
						<div class="swiper-container thm-swiper__slider" 
						data-swiper-options='{"slidesPerView": 1, "loop": true,
										"effect": "fade",
										"pagination": {
											"el": "#main-slider-pagination",
											"type": "bullets",
											"clickable": true
										},
										"autoplay": {
											"delay": 5000
										}}'>
							<div class="swiper-wrapper">
								<div class="swiper-slide">
									<div class="image-layer" style={{backgroundImage:` url(assets/images/main-slider/slider-1-1.jpg)`}}></div>
									
								
								
									<div class="container">
										<div class="row justify-content-end">
											<div class="col-xl-7 col-lg-12 text-right">
												<p>Help the poor in need</p>
												<h2>Lend the  helping hand  get involved.</h2>
												<a href="#" data-target=".donate-options" class="scroll-to-target thm-btn dynamic-radius">Start Donating</a>
											
											</div>
										</div>
									</div>
								</div>
								<div class="swiper-slide">
									<div class="image-layer"  style={{backgroundImage:` url(assets/images/main-slider/slider-1-2.jpg)`}}></div>

								
								
									<div class="container">
										<div class="row justify-content-end">
											<div class="col-xl-8 col-lg-12 text-right">
												<p>Help the poor in need</p>
												<h2>Donat<span class="iconic-text">i</span>on  Can Change  Life</h2>
												<a href="#" data-target=".donate-options" class="scroll-to-target thm-btn dynamic-radius">Start Donating</a>
										
											</div>
										</div>
									</div>
								</div>
								<div class="swiper-slide">
									<div class="image-layer" style={{backgroundImage:` url(assets/images/main-slider/slider-1-1.jpg)`}} ></div>
								
									<div class="container">
										<div class="row justify-content-end">
											<div class="col-lg-7 text-right">
												<p>Help the poor in need</p>
											
												<a href="#" data-target=".donate-options" class="scroll-to-target thm-btn dynamic-radius">Start Donating</a>
											
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="swiper-pagination" id="main-slider-pagination"></div>
						</div>
					</section>


					<section class="video-card-two">
					<div class="container wow fadeInUp" data-wow-duration="1500ms">
						<div class="inner-container dynamic-radius"  style={{backgroundImage:` url(assets/images/shapes/video-bg-1-1.png)`}} >
						
	
							<div class="row align-items-center">
								<div class="col-lg-3">
									<div class="video-card-two__box">
										<img src="assets/images/resources/video-1-1.png" alt=""/>
										<a href="https://www.youtube.com/watch?v=-2e_pjdYTZw" class="video-card-two__box-btn video-popup"><i class="fa fa-play"></i></a>
										
									</div>
								</div>
								<div class="col-lg-4">
									<h3>We’re worldwide non-profit <br />charity ogranization.</h3>
								</div>
								<div class="col-lg-5">
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Have you done google
										research which works all the time. </p>
								</div>
							</div>
						</div>
					</div>
					</section>

					<section class="service-one pt-120 pb-90" style={{backgroundImage:` url(assets/images/backgrounds/service-hand-bg-1-1.png)`}}>
						<div class="container">
							<img src="assets/images/shapes/service-line-1-1.png" alt="" class="service-one__shape-1"/>
							<div class="block-title">
								<p><img src="assets/images/shapes/heart-2-1.png" width="15" alt=""/>Welcome to Azino Charity</p>
								<h3>We believe that we can save;<br /> more lifes with you.</h3>
							</div>
							<div class="row">
								<div class="col-md-6 col-lg-3">
									<div class="service-one__box">
										<div class="service-one__icon background-secondary">
											<div class="service-one__icon-inner">
												<i class="azino-icon-water-bottle"></i>
											</div>
										</div>
										<h3><a href="#">Water</a></h3>
										<p>Lorem ipsum is simply free text available in the market wesbites.</p>
									</div>
								</div>
								<div class="col-md-6 col-lg-3">
									<div class="service-one__box">
										<div class="service-one__icon background-base">
											<div class="service-one__icon-inner">
												<i class="azino-icon-hamburger"></i>
											</div>
										</div>
										<h3><a href="#">Food</a></h3>
										<p>Lorem ipsum is simply free text available in the market wesbites.</p>
									</div>
								</div>
								<div class="col-md-6 col-lg-3">
									<div class="service-one__box ">
										<div class="service-one__icon background-primary">
											<div class="service-one__icon-inner">
												<i class="azino-icon-reading-book"></i>
											</div>
										</div>
										<h3><a href="#">Education</a></h3>
										<p>Lorem ipsum is simply free text available in the market wesbites.</p>
									</div>
								</div>
								<div class="col-md-6 col-lg-3">
									<div class="service-one__box">
										<div class="service-one__icon background-special">
											<div class="service-one__icon-inner">
												<i class="azino-icon-stethoscope"></i>
											</div>
										</div>
										<h3><a href="#">Medical</a></h3>
										<p>Lorem ipsum is simply free text available in the market wesbites.</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section class="about-counter pt-120">
					<div class="container">
							<div class="row">
								<div class="col-lg-6">
									<div class="block-title">
										<p><img src="assets/images/shapes/heart-2-1.png" width="15" alt=""/>Help People Now</p>
										<h3>Charity for the people <br/>you care about.</h3>
									</div>
									<p class="about-counter__text">Lorem Ipsum is simply dummy text of the printing and <br/> typesetting
										industry. Have you done google
										research which <br/> works all the time. </p>
									<ul class="list-unstyled ul-list-one">
										<li>Nsectetur cing elit.</li>
										<li>Suspe ndisse suscipit sagittis leo.</li>
										<li>Entum estibulum dignissim posuere.</li>
									</ul>
									<div class="about-counter__count">
										<h3 class="odometer" data-count="8860">00</h3>
										<p>Donation campaigns <br/> are running</p>
									</div>
								</div>
								<div class="col-lg-6 ">
									<div class="about-counter__image clearfix wow fadeInRight" data-wow-duration="1500ms">
										<div class="about-counter__image-content">
											<img src="assets/images/shapes/about-count-heart-1-1.png" alt=""/>
											<p>We’re here to support you every step of the way.</p>
										</div>
										<img src="assets/images/resources/about-counter-1-1.jpg" alt="" class="float-left"/>
									</div>
								</div>
							</div>
						</div>
					</section>


					<section class="causes-page causes-home pt-120 pb-120">
						<div class="container">
							<div class="row align-items-start align-items-md-center flex-column flex-md-row mb-60">
								<div class="col-lg-7">
									<div class="block-title">
										<p><img src="assets/images/shapes/heart-2-1.png" width="15" alt=""/>Popular Causes</p>
										<h3>Donate to charity causes <br/> around the world.</h3>
									</div>
								</div>
								<div class="col-lg-5 d-flex">
									<div class="my-auto">
										<p class="block-text pr-10 mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting
											industry.
											Have
											you done google research which works all the time. </p>
									</div>
								</div>
							</div>
							<div class="swiper-container thm-swiper__slider" data-swiper-options='{"slidesPerView": 3, "spaceBetween": 30,
										"breakpoints": {
											"0": {
												"slidesPerView": 1,
												"spaceBetween": 30
											},
											"375": {
												"slidesPerView": 1,
												"spaceBetween": 30
											},
											"575": {
												"slidesPerView": 1,
												"spaceBetween": 30
											},
											"768": {
												"slidesPerView": 1,
												"spaceBetween": 30
											},
											"991": {
												"slidesPerView": 2,
												"spaceBetween": 30
											},
											"1199": {
												"slidesPerView": 2,
												"spaceBetween": 30
											},
											"1200": {
												"slidesPerView": 3,
												"spaceBetween": 30
											}
										}
										}'>
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="cause-card">
                                <div class="cause-card__inner">
                                    <div class="cause-card__image">
                                        <img src="assets/images/causes/cause-1-1.jpg" alt=""/>
                                    </div>
                                    <div class="cause-card__content">
                                        <div class="cause-card__top">
                                            <div class="cause-card__progress">
                                                <span style={{width: '23%'}} class="wow cardProgress" data-wow-duration="1500ms">
                                                    <b><i>23</i>%</b>
                                                </span>
                                            </div>
                                            <div class="cause-card__goals">
                                                <p><strong>Raised:</strong> $25,270</p>
                                                <p><strong>Goal:</strong> $30,000</p>
                                            </div>
                                        </div>
                                        <h3><a href="cause-details.html">Our donation is hope for poor childrens</a></h3>
                                        <p>Lorem Ipsum simply dummy text of printng and type industry.</p>
                                        <div class="cause-card__bottom">
                                            <a href="cause-details.html" class="thm-btn dynamic-radius">Donate Now</a>
                                            
                                            <a href="#" class="cause-card__share" aria-label="share postr"><i class="azino-icon-share"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">

											<div class="cause-card">
												<div class="cause-card__inner">
													<div class="cause-card__image">
														<img src="assets/images/causes/cause-1-4.jpg" alt=""/>
													</div>
													<div class="cause-card__content">
														<div class="cause-card__top">
															<div class="cause-card__progress">
																<span style={{width: '65%'}} class="wow cardProgress" data-wow-duration="1500ms">
																	<b><i>65</i>%</b>
																</span>
															</div>
															<div class="cause-card__goals">
																<p><strong>Raised:</strong> $25,270</p>
																<p><strong>Goal:</strong> $30,000</p>
															</div>
														</div>
														<h3><a href="cause-details.html">Education for Poor Children</a></h3>
														<p>Lorem Ipsum simply dummy text of printng and type industry.</p>
														<div class="cause-card__bottom">
															<a href="cause-details.html" class="thm-btn dynamic-radius">Donate Now</a>
															
															<a href="#" class="cause-card__share" aria-label="share postr"><i class="azino-icon-share"></i></a>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="swiper-slide">

											<div class="cause-card">
												<div class="cause-card__inner">
													<div class="cause-card__image">
														<img src="assets/images/causes/cause-1-3.jpg" alt=""/>
													</div>
													<div class="cause-card__content">
														<div class="cause-card__top">
															<div class="cause-card__progress">
																<span style={{width: '55%'}} class="wow cardProgress" data-wow-duration="1500ms">
																	<b><i>55</i>%</b>
																</span>
															</div>
															<div class="cause-card__goals">
																<p><strong>Raised:</strong> $25,270</p>
																<p><strong>Goal:</strong> $30,000</p>
															</div>
														</div>
														<h3><a href="cause-details.html">Promoting The Rights of Children</a></h3>
														<p>Lorem Ipsum simply dummy text of printng and type industry.</p>
														<div class="cause-card__bottom">
															<a href="cause-details.html" class="thm-btn dynamic-radius">Donate Now</a>
														
															<a href="#" class="cause-card__share" aria-label="share postr"><i class="azino-icon-share"></i></a>
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</section>
						<section class="call-to-action-two">

								<div class="call-to-action-two__bg" style={{backgroundImage:` url(assets/images/backgrounds/page-header-1-1.jpg)`}}>
								
						
								</div>
								
								<div class="container pt-80 pb-80">
									<i class="azino-icon-charity call-to-action-two__icon"></i>
									<div class="row align-items-center">
										<div class="col-lg-7">
											<div class="block-title">
												<p><img src="assets/images/shapes/heart-2-1.png" width="15" alt=""/>Help Other People</p>
												<h3>Our fingerprints on <br/> the lives we touch <br/> never fade.</h3>
											</div>

										</div>
										<div class="col-lg-5 d-flex justify-content-start justify-content-lg-end">
											<div class="btn-wrap">
												<a href="#" data-target=".donate-options" class="scroll-to-target thm-btn dynamic-radius">Start
													Donating</a>
											</div>
										</div>
									</div>
								</div>
						</section>

						<section class="donate-options pt-120">
							<div class="container">
								<div class="row">
									<div class="col-lg-6">
										<div class="donate-options__content">
											<div class="block-title">
												<p><img src="assets/images/shapes/heart-2-1.png" width="15" alt=""/>Donate Now</p>
												<h3>Give a helping hand <br/> for a needy people.</h3>
											</div>
											<p>Lorem Ipsum is simply dummy text of the printing and <br/> typesetting industry. Have you done
												google research <br/> which works all the time. </p>
											<div class="donate-options__call">
												<i class="azino-icon-telephone"></i>
												<div class="donate-options__call-content">
													<p>Have any question about donation? <br/> <span>Call us now:</span> <a href="tel:666-888-0000">666 888 0000</a></p>
												</div>
											</div>
											<div class="donate-options__icon-wrap">
												<div class="donate-options__icon">
													<i class="azino-icon-dove"></i>
													<h3><a href="#">Living</a></h3>
												</div>
												<div class="donate-options__icon">
													<i class="azino-icon-hamburger"></i>
													<h3><a href="#">Food</a></h3>
												</div>
												<div class="donate-options__icon">
													<i class="azino-icon-family"></i>
													<h3><a href="#">Family</a></h3>
												</div>
											</div>
										</div>
									</div>
									<div class="col-lg-6">
										<form action="#" class="donate-options__form wow fadeInUp" data-wow-duration="1500ms">
											<h3 class="text-center">Start donating now</h3>
											<p class="text-center">Lorem ipsum dolor sit amet, conse ctetur <br/> adipisicing elit sed do eiusm
												od tempor ut labore.</p>
											<label for="donate-name" class="sr-only"></label>
											<input type="text" id="donate-name" placeholder="Your Name"/>
											<label for="donate-amount" class="sr-only"></label>
											<input type="text" value="" placeholder="Insert Value" id="donate-amount"/>
											<ul id="donate-amount__predefined" class="list-unstyled">
												<li><a href="#">$10</a></li>
												<li><a href="#">$20</a></li>
												<li><a href="#">$50</a></li>
											</ul>
											<button type="submit" class="thm-btn dynamic-radius">Donate Now</button>
											
										</form>
									</div>
								</div>
							</div>
						</section>

						<section class="team-about pt-120 pb-120" style={{backgroundImage:` url(assets/images/team/team-map-1-1.png)`}}>
						
						
							<div class="container">
								<div class="team-about__top">
									<div class="row align-items-center">
										<div class="col-md-12 col-lg-7">
											<div class="block-title">
												<p><img src="assets/images/shapes/heart-2-1.png" width="15" alt=""/>Our Volunteers</p>
												<h3>Meet those who help <br/> others in need.</h3>
											</div>
										</div>
										<div class="col-md-12 col-lg-5">
											<p class="team-about__top-text">Lorem Ipsum is simply dummy text of the printing and typesetting
												industry. Have you done google research which works all the time. </p>
										</div>
									</div>
								</div>
								<div class="team-4-col">
									<div class="wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
										<div class="team-card text-center content-bg-1">
											<div class="team-card__image">
												<img src="assets/images/team/team-2-1.jpg" alt=""/>
											</div>
											<div class="team-card__social">
												<a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
												<a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
												<a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
												<a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
											</div>
											<div class="team-card__content">
												<h3>Corey Dawson</h3>
												<p>Student</p>
											</div>
										</div>
									</div>
									<div class="wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
										<div class="team-card text-center content-bg-2">
											<div class="team-card__image">
												<img src="assets/images/team/team-2-2.jpg" alt=""/>
											</div>
											<div class="team-card__social">
												<a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
												<a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
												<a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
												<a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
											</div>
											<div class="team-card__content">
												<h3>Zachary Pope</h3>
												<p>Student</p>
											</div>
										</div>
									</div>
									<div class="wow fadeInDown" data-wow-duration="1500ms" data-wow-delay="200ms">
										<div class="team-card text-center content-bg-3">
											<div class="team-card__image">
												<img src="assets/images/team/team-2-3.jpg" alt=""/>
											</div>
											<div class="team-card__social">
												<a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
												<a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
												<a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
												<a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
											</div>
											<div class="team-card__content">
												<h3>Cole Erickson</h3>
												<p>Student</p>
											</div>
										</div>
									</div>
									<div class="wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="300ms">
										<div class="team-card text-center content-bg-4">
											<div class="team-card__image">
												<img src="assets/images/team/team-2-4.jpg" alt=""/>
											</div>
											<div class="team-card__social">
												<a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
												<a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
												<a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
												<a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
											</div>
											<div class="team-card__content">
												<h3>Violet Figueroa</h3>
												<p>Student</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>


						<div class="gallery-testimonials-parallax">
							<section class="gallery-home-one ">
								<div class="container-fluid">
									<div class="thm-swiper__slider swiper-container" data-swiper-options='{"spaceBetween": 100, "slidesPerView": 4, "autoplay": { "delay": 5000 }, "breakpoints": {
								"0": {
									"spaceBetween": 0,
									"slidesPerView": 1
								},
								"425": {
									"spaceBetween": 0,
									"slidesPerView": 1
								},
								"575": {
									"spaceBetween": 30,
									"slidesPerView": 2
								},
								"767": {
									"spaceBetween": 30,
									"slidesPerView": 2
								},
								"991": {
									"spaceBetween": 20,
									"slidesPerView": 3
								},
								"1289": {
									"spaceBetween": 20,
									"slidesPerView": 4
								},
								"1440": {
									"spaceBetween": 20,
									"slidesPerView": 5
								}
							}}'>
										<div class="swiper-wrapper">
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-1.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-1.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-2.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-2.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-3.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-3.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-4.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-4.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-5.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-5.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-1.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-1.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-2.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-2.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-3.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-3.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-4.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-4.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-5.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-5.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-1.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-1.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-2.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-2.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-3.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-3.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-4.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-4.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="gallery-card">
													<img src="assets/images/gallery/gallery-3-5.jpg" class="img-fluid" alt=""/>
													<div class="gallery-content">
														<a href="assets/images/gallery/gallery-3-5.jpg" class="img-popup" aria-label="open image"><i class="fal fa-plus"></i></a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>

						<section class="call-to-action">
						<div class="container wow fadeInLeft" data-wow-duration="1500ms">
							<div class="row align-items-center">
								<div class="col-xl-9 col-lg-8">
									<div class="call-to-action__content">
										<div class="call-to-action__icon">
											<i class="azino-icon-support"></i>
										</div>
										<h3>Let’s make a difference in <br/> the lives of others</h3>
									</div>
								</div>
								<div class="col-xl-3 col-lg-4 d-flex justify-content-start justify-content-md-center justify-content-xl-end justify-content-lg-end">
									<a href="become-volunteer.html" class="thm-btn dynamic-radius">Become a Volunteer</a>
								
								</div>
							</div>
						</div>
						</section>

					<Footerinfo />
			
				
				
	</div>
				<div class="mobile-nav__wrapper">
					<div class="mobile-nav__overlay mobile-nav__toggler"></div>
					
					<div class="mobile-nav__content">
						<span class="mobile-nav__close mobile-nav__toggler"><i class="far fa-times"></i></span>

						<div class="logo-box">
							<a href="index.html" aria-label="logo image"><img src="assets/images/logo-light.png" width="101" alt="" /></a>
						</div>
			
						<div class="mobile-nav__container"></div>
					
						<ul class="mobile-nav__contact list-unstyled">
							<li>
								<i class="azino-icon-email"></i>
								<a href="mailto:needhelp@azino.com">needhelp@azino.com</a>
							</li>
							<li>
								<i class="azino-icon-telephone"></i>
								<a href="tel:666-888-0000">666 888 0000</a>
							</li>
						</ul>
						<div class="mobile-nav__top">
							{/* <div class="mobile-nav__language">
								<img src="assets/images/resources/flag-1-1.jpg" alt=""/>
								<label class="sr-only" for="language-select">select language</label>
								<select class="selectpicker" id="language-select">
									<option value="english">English</option>
									<option value="arabic">Arabic</option>
								</select>
							</div> */}
							<div class="mobile-nav__social">
								<a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
								<a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
								<a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
								<a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
							</div>
						</div>



					</div>
					
				</div>
				
				
				<div class="search-popup">
					<div class="search-popup__overlay search-toggler"></div>
				
					<div class="search-popup__content">
						<form action="#">
							<label for="search" class="sr-only">search here</label>
							<input type="text" id="search" placeholder="Search Here..." />
							<button type="submit" aria-label="search submit" class="thm-btn">
								<i class="fa fa-search"></i>
							</button>
						</form>
					</div>
        
  				</div>			
									
	</>	
)

}

export default Home;

    