import React from 'react'
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';

export default function Headerinfo() {

    function refreshPage() {
        window.location.reload(false);
      }
  return (
    <div>
        <header class="main-header"> 
            <div class="container">
                <div class="inner-container">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-2">
                            <div class="logo-box">
                                      <Link  to="/" >
                                            <img src="assets/images/logo-dark.png" width="101" alt=""/>
                                    </Link>
                                <span class="fa fa-bars mobile-nav__toggler"></span>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10 d-none d-md-none d-lg-none d-xl-block">
                            <div class="main-header__top">
                                
                                <div class="main-header__social">
                                    <a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
                                    <a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
                                    <a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
                                    <a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <nav class="main-menu dynamic-radius">
                                <ul class="main-menu__list">
                                    <li>
                                             <Link  to="/"  onClick={refreshPage}>Home</Link>
                                  
                                    </li>
                                    <li>
                                            <Link  to="/contact" onClick={refreshPage}>Contact</Link>
                                       
                                    </li>
                                    <li class="dropdown"><a href="events.html">Events</a>
                                        <ul>
                                            <li><a href="events.html">Events</a></li>
                                            <li><a href="event-details.html">Event Details</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown"><a href="news.html">News</a>
                                        <ul>
                                            <li><a href="news.html">News</a></li>
                                            <li><a href="news-details.html">News Details</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown"><a href="#">Pages</a>
                                        <ul>
                                            <li><a href="about.html">About</a></li>
                                            <li><a href="volunteers.html">Volunteers</a></li>
                                            <li><a href="become-volunteer.html">Become a Volunteer</a></li>
                                            <li><a href="gallery.html">Gallery</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                    <Link class="nav-item nav-link active" to="/contact">Contact Us</Link>
                                    
                                    </li>
                                    <li class="search-btn search-toggler">
                                        <a href="#"><i class="azino-icon-magnifying-glass"></i></a>
                                    </li>
                                </ul>
                             
                            </nav>
                        
                            <div class="main-header__info">
                                <ul class="list-unstyled main-header__info-list">
                                    <li>
                                        <i class="azino-icon-email"></i>
                                        <a href="mailto:needhelp@azino.com">needhelp@azino.com</a>
                                    </li>
                                    <li>
                                        <i class="azino-icon-telephone"></i>
                                        <a href="tel:666-888-0000">666 888 0000</a>
                                    </li>
                                </ul>
                                {/* <div class="main-header__language">
                                    <div class="main-header__language-image">
                                        <img src="assets/images/resources/flag-1-1.jpg" alt=""/>
                                    </div>
                                    <label for="language-header" class="sr-only">select language</label>
                                    <select class="selectpicker" id="language-header">
                                        <option value="english">English</option>
                                        <option value="arabic">Arabic</option>
                                    </select>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </header>
        <div class="stricky-header stricked-menu">
						<div class="container">
							<div class="logo-box">
								<a href="index.html"><img src="assets/images/logo-light.png" width="101" alt=""/></a>
							</div>
							<div class="sticky-header__content"></div>
						</div>
		</div>
       
            
    </div>
  )
}
