import React from 'react';

import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Headerinfo from './Headerinfo';
import Footerinfo from './Footerinfo';

function Contact (){
return(
    <>

  
    <div class="page-wrapper">
      <Headerinfo />
	  
            <section class="page-header">
                        <div class="page-header__bg" style={{backgroundImage:` url(assets/images/backgrounds/page-header-1-1.jpg)`}} ></div>
                   
                        <div class="container">
                            <h2>Contact Page</h2>
                            <ul class="thm-breadcrumb list-unstyled dynamic-radius">
                                <li>
                                 <Link class="nav-item nav-link active" to="/" >Home</Link>
                                </li>
                                <li>-</li>
                                <li><span>Contact</span></li>
                            </ul>
                        </div>
                    </section>

                    <section class="contact-page pt-120 pb-80">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="contact-page__content mb-40">
                                        <div class="block-title">
                                            <p><img src="assets/images/shapes/heart-2-1.png" width="15" alt=""/>Contact With Us</p>
                                            <h3>Feel free to write us <br/> a message.</h3>
                                        </div>
                                        <p class="block-text mb-30 pr-10">Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry.
                                            Have you done google
                                            research which works all the time. </p>
                                        <div class="footer-social black-hover">
                                            <a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
                                            <a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
                                            <a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
                                            <a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <form action="assets/inc/sendemail.php" class="contact-form-validated contact-page__form form-one mb-40">
                                        <div class="form-group">
                                            <div class="form-control">
                                                <label for="name" class="sr-only">Name</label>
                                                <input type="text" name="name" id="name" placeholder="Your Name"/>
                                            </div>
                                            <div class="form-control">
                                                <label for="email" class="sr-only">email</label>
                                                <input type="text" name="email" id="email" placeholder="Email Address"/>
                                            </div>
                                            <div class="form-control">
                                                <label for="phone" class="sr-only">phone</label>
                                                <input type="text" name="phone" id="phone" placeholder="Phone Number"/>
                                            </div>
                                            <div class="form-control">
                                                <label for="subject" class="sr-only">subject</label>
                                                <input type="text" name="subject" id="subject" placeholder="Subject"/>
                                            </div>
                                            <div class="form-control form-control-full">
                                                <label for="message" class="sr-only">message</label>
                                                <textarea name="message" placeholder="Write a Message" id="message"></textarea>
                                            </div>
                                            <div class="form-control form-control-full">
                                                <button type="submit" class="thm-btn dynamic-radius">Submit Message</button>
                                                
                                            </div>
                                        </div>
                                    </form>
                                    <div class="result"></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div class="google-map__contact">
                        <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd" class="map__contact" allowfullscreen></iframe>
                    </div>
        <Footerinfo/>
    </div>   
            <div class="mobile-nav__wrapper">
                <div class="mobile-nav__overlay mobile-nav__toggler"></div>
                
                <div class="mobile-nav__content">
                    <span class="mobile-nav__close mobile-nav__toggler"><i class="far fa-times"></i></span>

                    <div class="logo-box">
                        <a href="index.html" aria-label="logo image"><img src="assets/images/logo-light.png" width="101" alt="" /></a>
                    </div>
                    
                    <div class="mobile-nav__container"></div>
              

                    <ul class="mobile-nav__contact list-unstyled">
                        <li>
                            <i class="azino-icon-email"></i>
                            <a href="mailto:needhelp@azino.com">needhelp@azino.com</a>
                        </li>
                        <li>
                            <i class="azino-icon-telephone"></i>
                            <a href="tel:666-888-0000">666 888 0000</a>
                        </li>
                    </ul>
                    <div class="mobile-nav__top">
                        {/* <div class="mobile-nav__language">
                            <img src="assets/images/resources/flag-1-1.jpg" alt="">
                            <label class="sr-only" for="language-select">select language</label>
                            <select class="selectpicker" id="language-select">
                                <option value="english">English</option>
                                <option value="arabic">Arabic</option>
                            </select>
                        </div> */}
                        <div class="mobile-nav__social">
                            <a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
                            <a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
                            <a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
                            <a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
                        </div>



                    </div>
                </div>
                
            </div>
    
    	
			
  </>
         
) 
}

export default Contact;
