import React from 'react'
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';

export default function Footerinfo() {
  return (
    <div>
              
        <section class="site-footer">
            <div class="main-footer pt-142 pb-80">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="footer-widget mb-40 footer-widget__about">
                                <a href="index.html" aria-label="logo image">
                                    <img src="assets/images/logo-light.png" class="footer-widget__logo" width="101" alt=""/>
                                </a>
                                <p>Lorem ipsum dolor sit amet consect etur adi pisicing elit sed.</p>
                                <ul class="list-unstyled footer-widget__contact">
                                    <li><a href="#"><i class="azino-icon-telephone"></i>666 888 0000</a></li>
                                    <li><a href="#"><i class="azino-icon-email"></i>needhelp@azino.com</a></li>
                                    <li><a href="#"><i class="azino-icon-pin"></i>88 Broklyn Golden Street, USA</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="footer-widget footer-widget__link mb-40">
                                <h3 class="footer-widget__title">Explore</h3>
                                <ul class="list-unstyled footer-widget__link-list">
                                    <li><a href="causes.html">Our Causes</a></li>
                                    <li><a href="about.html">About us</a></li>
                                    <li><a href="news.html">New Campaign</a></li>
                                    <li><a href="events.html">Upcoming Events</a></li>
                                    <li><a href="about.html">Site Map</a></li>
                                    <li>
                                    <Link class="nav-item nav-link active" to="/contact">Help</Link>
                                    
                                    </li>
                                    <li><a href="causes.html">Donate</a></li>
                                    <li>
                                    <Link class="nav-item nav-link active" to="/contact">Contact</Link>
                                    </li>
                                    <li><a href="contact.html">Terms</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="footer-widget mb-40 footer-widget__blog">
                                <h3 class="footer-widget__title">Blog</h3>
                                <ul class="list-unstyled footer-widget__blog">
                                    <li>
                                        <img src="assets/images/resources/footer-img-1-1.jpg" alt=""/>
                                        <p>22 May, 2020</p>
                                        <h3><a href="news-details.html">You can help the poor in need</a></h3>
                                    </li>
                                    <li>
                                        <img src="assets/images/resources/footer-img-1-2.jpg" alt=""/>
                                        <p>22 May, 2020</p>
                                        <h3><a href="news-details.html">Rise fund for Healthy Food</a></h3>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="footer-widget mb-40 footer-widget__newsletter">
                                <h3 class="footer-widget__title">Newletter</h3>
                                <p>Signup now to get daily latest news
                                    & updates from us</p>
                                <form data-url="https://xyz.us18.list-manage.com/subscribe/post?u=20e91746ef818cd941998c598&id=cc0ee8140e" class="footer-widget__newsletter-form mc-form">
                                    <label for="mc-email" class="sr-only">Email Address</label>
                                    <input type="email" name="EMAIL" id="mc-email" class="dynamic-radius" placeholder="Email address"/>
                                    <div class="footer-widget__newsletter-btn-wrap d-flex justify-content-end">
                                        <button type="submit" class="thm-btn dynamic-radius">Subscribe Now</button>
                                    </div>
                                   
                                </form>
                                <div class="mc-form__response"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <span class="scroll-to-top scroll-to-target" data-target="html"><i class="far fa-angle-up"></i></span>
                    <p>© Copyright 2020 by Layerdrops.com</p>
                    <div class="footer-social">
                        <a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
                        <a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
                        <a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
                        <a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </section>
       
            
    </div>
  )
}
